import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { LocalStoreService } from '../services/localstore.service';
import { KioskauthService } from '../services/kioskauth.service';
import { ApiService } from '../services/api.service';
import { UtilityService } from '../services/utility.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateConfigService } from '../services/translate-config.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';
import { TranslateService } from '@ngx-translate/core';
import { payment_timer, setIntervalTimeOut, telemetryTimeout, KIOSK_AUTO_LOGIN, apitimeout, enable_test_buttons, terminalCheck } from '../common/api';
import { timeout } from 'rxjs/operators';
import { KioskapiService } from '../services/kioskapi.service';
import { CancelPaymentPage } from './cancel-payment/cancel-payment.page';
import { environment } from 'src/environments/environment';
import * as sha1 from 'js-sha1';


@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.page.html',
  styleUrls: ['./payment-method.page.scss'],
})
export class PaymentMethodPage implements OnInit {
  counter;
  terminalid;
  cartItems: any = [];
  totalAmount: number;
  currency = "¥";
  showDecimal = true;
  interval: any;
  proc_card_idi;
  pay_sub: any;
  createorderRes;
  wait_for_payment;
  temp_balance: any;
  temp_total: any;
  _suica_alert: any;
  suica_alert_flag = 0;
  ServiceID: any;
  modal_time_count = 0;
  modal_timer;
  time_stop = false;
  show_cancel_button = false;
  mid_cancel = false;
  page_redirection_time_left = payment_timer;
  time_left_redirection_counter: any;
  attempted: any;
  deducted: any;
  balance: any;
  card_idi: any;
  ic_handling_number: any;
  order_UUID;
  isPaymentFailed;
  app_settings;
  message: any;
  test_pay_sub;
  test_button_status = enable_test_buttons;
  order_uuid;
  PSKIOSK;
  userCart;
  cart_obj;
  telemetry_poller;
  loading;
  terminal_modal;

  constructor(
    private store: LocalStoreService,
    private router: Router,
    private loadingCtrl: LoadingController,
    private kioskauthservice: KioskauthService,
    public alertController: AlertController,
    private api: ApiService,
    private translateConfigService: TranslateConfigService,
    private utilityService: UtilityService,
    private translate: TranslateService,
    public modalController: ModalController,
    private kiosk_apiService: KioskapiService,
  ) {
    this.translateConfigService.getDefaultLanguage();

    this.showDecimal = true;
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      if (countryData['master_country_currency_decimal_places'] == 0) {
        this.showDecimal = false;
      }
    }
  }

  ngOnInit() {
    this.counter = 0;
    this.Page_timer();
  }



  ionViewWillEnter() {
    this.terminalid = this.store.get('terminalid')
    this.isRestaurantUnderMaintainance();
    this.payment_printing_setting();
    this.terminal_modal = this.store.get('terminal_modal')
    setInterval( () =>{
      this.terminal_modal = this.store.get('terminal_modal')
    },500)
    this.kiosk_apiService.telemetry();
    this.telemetry_poller = setInterval(
      ()=>{
       this.kiosk_apiService.telemetry()
      }
    , terminalCheck);
  }

  ionViewDidEnter() {
    this.counter = 0;
  }

  isRestaurantUnderMaintainance() {
    let param = {
      master_restaurant_uuid: localStorage.getItem("master_restaurant_uuid")
    }
    this.kioskauthservice.apiReq('post', 'masterrestaurant/getbyuuid', param)
      .subscribe((res) => {
        if (res.statusCode == 200) {
          if (res.aaData['master_restaurant_status'] == 'M_ON') {
            this.router.navigate([`home`]);
          } else {
            this.loadAllData();
          }
        } else {
          this.loadAllData();
        }
        if (res.statusCode != 200) {
          if (res.aaData == false) {
            this.error()
          }
        }
      }, err => {
        this.error()
      });
  }

  Page_timer() {

    clearInterval(this.time_left_redirection_counter);
    this.page_redirection_time_left = payment_timer;

    if (this.page_redirection_time_left = payment_timer) {

      this.time_left_redirection_counter = setInterval(() => {
        this.page_redirection_time_left -= 1;
        if (this.page_redirection_time_left == 0) {
          this.kiosk_apiService.terminal_dismiss()
          clearInterval(this.telemetry_poller)
          clearInterval(this.time_left_redirection_counter);
          this.store.setUserCart([]);
          this.store.remove("formFields");
          this.store.remove("cart");
          this.time_out();
          return false;
        }
      }, setIntervalTimeOut);
    }
  }


  loadAllData() {
    const orderId = this.store.get('orderId')
    const items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
    if (items.length == 0 && !orderId) {
      this.router.navigate([`home`]);
      return false;
    } else {
      this.create_kiosk_order();
    }

  }

  create_kiosk_order() {
    let cartDetail = this.store.getUserCart();
    this.totalAmount = 0;
    this.cartItems = [];
    Object.entries(cartDetail).forEach(([k, v]) => {
      this.totalAmount = v[0].total + +this.totalAmount;
      Object.entries(v).forEach(([key, value]) => {
        this.cartItems.push(value);
      })
    });
    let order_type = "TAKE_AWAY";
    if (this.store.get("order_type") == "Dine In") {
      order_type = "DINE_IN";
    }

    if (this.terminalid == 1) {
      this.PSKIOSK = "PS_KIOSK_R"
    } else {
      this.PSKIOSK = "PS_KIOSK_L"
    }

    let totalCartAmount = this.totalAmount;
    let cartItemCount = this.store.get("cartCartItemCount")
    let user = this.store.getUser();
    this.userCart = this.store.getUserCart()
    this.cart_obj = this.userCart
    this.attempted = this.totalAmount;
    this.ServiceID = this.generate_rand_num();
    let envi_key = environment.key_for_sha1
    let concat_key = this.totalAmount + envi_key
    let sha1_key = sha1(concat_key)
    let data = {
      session_id: this.store.get('sessionId'),
      order_info_uuid: this.store.get('orderId'),
      order_info_customer: user.user_information_uuid,
      master_restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
      order_info_total_price: totalCartAmount,
      order_info_total_order_quantity: 0,
      order_info_total_discount: 0,
      available_redeemed_amount: 0,
      points_redeemed_amount: 0,
      master_coupon_uuid: null,
      master_subscription_uuid: null,
      subscription_mapping_uuid: null,
      used_subscription_quantity: 0,
      order_info_gst: 0,
      order_info_grand_total: totalCartAmount,
      order_info_delivery_date: "",
      master_restaurant_type: this.store.getRestaurant().master_restaurant_type,
      orders: this.cartItems,
      order_info_table_number: null,
      order_info_car_plate_number: null,
      order_info_cutlery: 'FALSE',
      order_info_type: order_type,
      order_info_dinningmode: null,
      order_info_dinningtime: null,
      delivery_address: null,
      is_sap_order: "NO",
      country_uuid: this.store.getCountry().master_country_uuid,
      request_from: this.PSKIOSK,
      deducted: this.deducted,
      balance: this.balance,
      serviceID: this.ServiceID,
      hmac: sha1_key,
    }

    this.kiosk_apiService.logEvents("PAYMENT", data, "CREATE_ORDER_PAYLOAD");
    this.kiosk_apiService.audit_log("PAYMENT", data, "CREATE_ORDER_PAYLOAD");


    this.kiosk_apiService.create_kiosk_order(data).subscribe((res) => {
      this.kiosk_apiService.logEvents("PAYMENT", res, "CREATE_ORDER_RES");
      this.kiosk_apiService.audit_log("PAYMENT", res, "CREATE_ORDER_RES");
      if (res.statusCode == 200) {

        this.createPayment(res);
        this.store.set("orderId", res.aaData['order_info_uuid']);
        if (res.aaData['master_restaurant_status'] == 'M_ON') {
          this.router.navigate([`home`]);
        } else {
        }
      } else {
        if (res.statusCode != 200) {
          if (res.message) {
            this.technical_error(res.message)
          } else {
            this.technical_error();
          }
        }
      }
    }, err => {
      this.kiosk_apiService.logEvents("PAYMENT", err, "CREATE_ORDER_ERR");
      this.kiosk_apiService.audit_log("PAYMENT", err, "CREATE_ORDER_ERR");
    })
  }

  createPayment(orderData) {
    this.createorderRes = orderData
    if (this.app_settings.enable_kiosk_payment) {
      let TransactionID = orderData.aaData.reference_number
      let date = new Date()
      console.log(date)
      const paymentQueryParams = {
        serviceID: this.ServiceID,
        totalAmount: this.totalAmount,
        terminalId: this.terminalid,
        transactionID: orderData.aaData.order_info_uuid
      }
      this.kiosk_apiService.logEvents("PAYMENT", paymentQueryParams, "PAYMENT_PAYLOAD");
      this.kiosk_apiService.audit_log("PAYMENT", paymentQueryParams, "PAYMENT_PAYLOAD");
      this.pay_sub = this.api.wait_for_payment(this.ServiceID, this.totalAmount, this.terminalid, TransactionID).pipe(timeout(apitimeout)).subscribe(
        r => {
          clearInterval(this.interval);
          let result1 = this.stripslashes(r)
          console.log(result1.length, "replace1", result1)
          let result2 = result1.replace(/“/g, '"');
          console.log(result2.length, "replace2", result2)
          this.wait_for_payment = result2.replace(/”/g, '"');
          let lengths = this.wait_for_payment.length
          if (this.wait_for_payment.charAt(0) == '"' && this.wait_for_payment.charAt(lengths - 1) == '"') {
            this.wait_for_payment = this.wait_for_payment.slice(1, -1)
          }
          console.log("Response 1 ======>", r);
          if (this.kiosk_apiService.isJsonString(this.wait_for_payment)) {
            console.log("Response 1.1 ======>", this.wait_for_payment);
            this.wait_for_payment = JSON.parse(this.wait_for_payment)
          } else {
            console.log("Response 1.2 ======>", r);
            this.wait_for_payment = r
          }
          console.log("Response 1.3 ======>", this.wait_for_payment);
          this.payment_response_process(this.wait_for_payment) // new logic function
        },
        err => {
          console.log("PAYMNET API ERROR RESPONSE========", err)
          if (err.error) {
            let result1 = this.stripslashes(err.error)
            let result2 = result1.replace(/“/g, '"');
            this.wait_for_payment = result2.replace(/”/g, '"');
            let lengths = this.wait_for_payment.length
            if (this.wait_for_payment.charAt(0) == '"' && this.wait_for_payment.charAt(lengths - 1) == '"') {
              this.wait_for_payment = this.wait_for_payment.slice(1, -1)
            }
            if (this.kiosk_apiService.isJsonString(this.wait_for_payment)) {
              this.wait_for_payment = JSON.parse(this.wait_for_payment)
            } else {
              this.wait_for_payment = err.error
            }
            if (this.wait_for_payment.aaData && this.wait_for_payment.aaData.message) {
              this.store.remove("totalAmount");
              this.store.remove("totalCartAmount");
              this.payment_response_modal(this.wait_for_payment.aaData.message)
            } else {
              // this.error();
            }
          }
          else {
            // this.error();
          }
          this.kiosk_apiService.logEvents("PAYMENT", err, "PAYMENT_TIMEOUT");
          this.kiosk_apiService.audit_log("PAYMENT", err, "PAYMENT_TIMEOUT");
        }
      );
    }
    else {
      this.updateOrder(orderData, null, "QUEUED");
    }
  }

  payment_response_process(payment_info) {
    if (payment_info.statusCode == 200) {
   
      this.store.remove("totalAmount");
      this.store.remove("totalCartAmount");
      this.kiosk_apiService.logEvents("PAYMENT", payment_info, "SUCCESS");
      this.kiosk_apiService.audit_log("PAYMENT", payment_info, "SUCCESS");
      this.updateOrder(this.createorderRes, payment_info, "QUEUED");
    }
    else {
      console.log("paymnet info message.............",payment_info.message)
      this.store.remove("totalAmount");
      this.store.remove("totalCartAmount");
      this.kiosk_apiService.logEvents("PAYMENT", payment_info, "PAYMENT_ERROR");
      this.kiosk_apiService.audit_log("PAYMENT", payment_info, "PAYMENT_ERROR");
      this.payment_response_modal(payment_info.message)
    }
  }

  updateOrder(orderData, paymentResponse, isPaymentFailed) {
    this.deducted = this.totalAmount;
    // let terminalid = this.store.get('terminalid')
    this.order_UUID = orderData.aaData.order_info_uuid
    this.isPaymentFailed = isPaymentFailed;
    this.balance = paymentResponse.aaData ? paymentResponse.aaData.posOriginalAmountValue : "";
    let mid = paymentResponse.aaData ? paymentResponse.aaData.mid : "";
    let data = {
      order_info_uuid: this.order_UUID,
      restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
      payment_response: paymentResponse,
      payment_status: isPaymentFailed ? isPaymentFailed : '',
      suica_payment_details: { "deducted": this.deducted, "balance": this.balance, "paymentid": mid, "RESPONSE": paymentResponse }
    }

    this.kiosk_apiService.logEvents("PAYMENT", data, "UPDATE_ORDER_PAYLOAD");
    this.kiosk_apiService.audit_log("PAYMENT", data, "UPDATE_ORDER_PAYLOAD");

    this.kiosk_apiService.updateOrder(data).subscribe(res => {
      this.kiosk_apiService.logEvents("PAYMENT", res, "UPDATE_ORDER_RESPONSE");
      this.kiosk_apiService.audit_log("PAYMENT", res, "UPDATE_ORDER_RESPONSE");
      this.store.remove("orderId");
      this.store.remove("totalAmount");
      this.store.remove("totalCartAmount");
      if (this.isPaymentFailed == 'QUEUED' && this.app_settings.enable_kiosk_print) {

        this.router.navigateByUrl('/settlement-complete?id=' + this.order_UUID + "&c=" + this.proc_card_idi + "&b=");
      }
      else if (!this.app_settings.enable_kiosk_print && this.isPaymentFailed == 'QUEUED') {
        clearInterval(this.time_left_redirection_counter);
        let Autologin = KIOSK_AUTO_LOGIN
        this.store.hardlogout()
        location.href = Autologin + '/' + this.terminalid;
      }
      return false;
    }, err => {
      this.kiosk_apiService.logEvents("PAYMENT", err, "UPDATE_ERR");
      this.kiosk_apiService.audit_log("PAYMENT", err, "UPDATE_ERR");
      const payment_cancel_timeout = setTimeout(() => {
        clearInterval(this.time_left_redirection_counter);
        let Autologin = KIOSK_AUTO_LOGIN
        let terminalid = this.store.get('terminalid')
        this.store.hardlogout()
        location.href = Autologin + '/' + terminalid;
      }, 100)
    }
    );

  }

  cancelOrder() {
    clearInterval(this.time_left_redirection_counter);
    this.mid_cancel = true;
    let cancel_payment = {
      serviceId: this.ServiceID,
      terminalid: this.terminalid
    }
    this.kiosk_apiService.logEvents("PAYMENT", cancel_payment, "CANCEL_PAYMENT_PAYLOAD");
    this.kiosk_apiService.audit_log("PAYMENT", cancel_payment, "CANCEL_PAYMENT_PAYLOAD");
    this.api.wait_for_abort_payment(this.ServiceID, this.terminalid)
      .subscribe(res => {
        console.log("Unsubcribed payment api=====>>>")
        this.pay_sub.unsubscribe();
        this.kiosk_apiService.logEvents("PAYMENT", res, "CANCEL");
        this.kiosk_apiService.audit_log("PAYMENT", res, "CANCEL");
        const paymnet_cancel_timeout = setTimeout(() => {
          clearInterval(this.time_left_redirection_counter);
          let Autologin = KIOSK_AUTO_LOGIN
          this.store.hardlogout()
          location.href = Autologin + '/' + this.terminalid;
        }, 100)
      }, (err) => {
        console.log("Unsubcribed payment api=====>>>")
        this.pay_sub.unsubscribe();
        this.kiosk_apiService.logEvents("PAYMENT", err, "CANCEL");
        this.kiosk_apiService.audit_log("PAYMENT", err, "CANCEL");
        const paymnet_cancel_timeout = setTimeout(() => {
          clearInterval(this.time_left_redirection_counter);
          let Autologin = KIOSK_AUTO_LOGIN
          this.store.hardlogout()
          location.href = Autologin + '/' + this.terminalid;
        }, 200)
      }
      )
  }


  abort_order() {
    this.pay_sub.unsubscribe()
    let terminalid = this.store.get('terminalid')
    let data = {
      serviceId: this.ServiceID,
      terminalid: terminalid
    }
    this.kiosk_apiService.logEvents("PAYMENT", data, "ABORT_ORDER_PAYLOAD");
    this.kiosk_apiService.audit_log("PAYMENT", data, "ABORT_ORDER_PAYLOAD");

    this.api.wait_for_abort_payment(this.ServiceID, terminalid)
      .subscribe(res => {
        this.kiosk_apiService.logEvents("PAYMENT", res, "ABORT");
        this.kiosk_apiService.audit_log("PAYMENT", res, "ABORT");
      });

  }


  generate_rand_num() {
    var rand: any;
    rand = Math.random();
    rand = rand * 10000000000;
    rand = Math.floor(rand);
    rand = '' + rand;
    rand = rand.substr(0, 8);
    return rand;
  }

  payment_printing_setting() {
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    let data = {
      "master_restaurant_uuid": master_restaurant_uuid
    }
    this.kiosk_apiService.payment_printing_setting(data)
      .subscribe(
        (res) => {
          this.app_settings = res.aaData.module_permissions
        },
        error => {
        }
      );
  }

  make_payment() {
    this.pay_sub.unsubscribe();
    this.createPayment(this.createorderRes);
  }

  go_to_maintenance() {
    let terminalid = localStorage.getItem('terminalid')
    terminalid = JSON.parse(terminalid)
    location.href = "http://192.168.10.11:9001/?terminalid=" + terminalid;
  }

  TestCreatePayment(buttonstatus) {
    this.pay_sub.unsubscribe();
    let TransactionID = this.createorderRes.aaData.reference_number
    let order_info_uuid = this.store.get('orderId')
    if (this.app_settings.enable_kiosk_payment) {
      const paymentQueryParams = {
        serviceID: this.ServiceID,
        totalAmount: this.totalAmount,
        terminalId: this.terminalid,
      }
      this.kiosk_apiService.logEvents("PAYMENT", paymentQueryParams, "PAYMENT_PAYLOAD");
      this.kiosk_apiService.audit_log("PAYMENT", paymentQueryParams, "PAYMENT_PAYLOAD");
      this.test_pay_sub = this.api.wait_for_Test_payment(this.ServiceID, this.totalAmount, this.terminalid, buttonstatus, order_info_uuid, TransactionID).subscribe(
        r => {
          let result1 = this.stripslashes(r)
          let result2 = result1.replace(/“/g, '"');
          this.wait_for_payment = result2.replace(/”/g, '"');
          let lengths = this.wait_for_payment.length
          if (this.wait_for_payment.charAt(0) == '"' && this.wait_for_payment.charAt(lengths - 1) == '"') {
            this.wait_for_payment = this.wait_for_payment.slice(1, -1)
          }
          if (this.kiosk_apiService.isJsonString(this.wait_for_payment)) {
            this.wait_for_payment = JSON.parse(this.wait_for_payment)
          } else {
            this.wait_for_payment = r
          }
          this.payment_response_process(this.wait_for_payment) // new logic function
        },
        err => {
          console.log("PAYMNET API ERROR RESPONSE========", err)
          if (err.error) {
            let result1 = this.stripslashes(err.error)
            let result2 = result1.replace(/“/g, '"');
            this.wait_for_payment = result2.replace(/”/g, '"');
            let lengths = this.wait_for_payment.length
            if (this.wait_for_payment.charAt(0) == '"' && this.wait_for_payment.charAt(lengths - 1) == '"') {
              this.wait_for_payment = this.wait_for_payment.slice(1, -1)
            }
            if (this.kiosk_apiService.isJsonString(this.wait_for_payment)) {
              this.wait_for_payment = JSON.parse(this.wait_for_payment)
            } else {
              this.wait_for_payment = err.error
            }
            if (this.wait_for_payment.aaData && this.wait_for_payment.aaData.message) {
              this.store.remove("totalAmount");
              this.store.remove("totalCartAmount");
              this.payment_response_modal(this.wait_for_payment.aaData.message)
            } else {
              this.error();
            }
          }
          else {
            this.error();
          }
          this.kiosk_apiService.logEvents("PAYMENT", err, "PAYMENT_TIMEOUT");
          this.kiosk_apiService.audit_log("PAYMENT", err, "PAYMENT_TIMEOUT");
        }

      );

    }
    else {
      this.updateOrder(this.createorderRes, null, "QUEUED");
    }
  }




  stripslashes(str) {
    // discuss at: https://locutus.io/php/stripslashes/
    // original by: Kevin van Zonneveld (https://kvz.io)
    // improved by: Ates Goral (https://magnetiq.com)
    // improved by: marrtins
    // improved by: rezna
    // fixed by: Mick@el
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // bugfixed by: Brett Zamir (https://brett-zamir.me)
    // input by: Rick Waldron
    // input by: Brant Messenger (https://www.brantmessenger.com/)
    // reimplemented by: Brett Zamir (https://brett-zamir.me)
    // example 1: stripslashes('Kevin\'s code')
    // returns 1: "Kevin's code"
    // example 2: stripslashes('Kevin\\\'s code')
    // returns 2: "Kevin\'s code"
    return (str + '')
      .replace(/\\(.?)/g, function (s, n1) {
        switch (n1) {
          case '\\':
            return '\\'
          case '0':
            return '\u0000'
          case '':
            return ''
          default:
            return n1
        }
      })
  }


  ionViewDidLeave() {
    clearInterval(this.time_left_redirection_counter);
    console.log("Unsubcribed payment api=====>>>")
    this.pay_sub.unsubscribe();
    clearInterval(this.terminal_modal)
    clearInterval(this.telemetry_poller);
    this.store.setUserCart([]);
    this.store.remove("selectedItemsInCart");
    this.store.remove("formFields");
    this.store.remove("cart");
    this.store.remove('orderId')
  }





  //  pop_modals
  async time_out() {
    this.abort_order()

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      backdropDismiss: false,
      message: "<h1>Time out</h1>",
      buttons: [
        {
          text: 'Done',
          handler: () => {
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            this.store.remove('orderId')
            let Autologin = KIOSK_AUTO_LOGIN

            this.store.hardlogout()
            location.href = Autologin + '/' + this.terminalid;
          }
        },
        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            this.store.remove('orderId')
            let Autologin = KIOSK_AUTO_LOGIN

            this.store.hardlogout()
            location.href = Autologin + '/' + this.terminalid;
          }
        }
      ]
    });
    setTimeout(() => {
      this.store.setUserCart([]);
      this.store.remove("formFields");
      this.store.remove("cart");
      this.store.remove('orderId')
      let Autologin = KIOSK_AUTO_LOGIN
      this.store.hardlogout()
      location.href = Autologin + '/' + this.terminalid;
    }, 6000);
    await alert.present().then(data => {
    });
  }

  //Abort payment
  async cancel_payment() {
    clearInterval(this.time_left_redirection_counter);
    const alert = await this.modalController.create({
      component: CancelPaymentPage,
      cssClass: 'cancel-payment'
    }
    );
    return await alert.present();
  }

  //payment failed
  async payment_failed() {

    clearInterval(this.time_left_redirection_counter);
    this.message = "Please tap again";
    if (this.counter == 2) {
      this.message = '';
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      backdropDismiss: false,
      header: 'Payment Failed',
      message: '<h1>' + this.message + '</h1> ',
      buttons: [
        {
          text: 'Done',
          handler: () => {
            clearTimeout(paymnet_failed_timeout)
            this.Page_timer()
            this.counter++;
            this.abort_order()
            this.make_payment();
            if (this.counter == 3) {
              text: ''
              this.abort_order()
              this.store.setUserCart([]);
              this.store.remove("formFields");
              this.store.remove("cart");
              this.store.remove('orderId')
              this.alertController.dismiss();
              let Autologin = KIOSK_AUTO_LOGIN
              this.store.hardlogout()
              location.href = Autologin + '/' + this.terminalid;
            }
          }
        },
        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            clearTimeout(paymnet_failed_timeout)
            this.Page_timer()
            this.alertController.dismiss();
            this.router.navigateByUrl('/payment-method');
            this.counter++;
            this.abort_order();
            this.make_payment();
            if (this.counter == 3) {
              this.abort_order();
              this.store.setUserCart([]);
              this.store.remove("formFields");
              this.store.remove("cart");
              this.store.remove('orderId')
              this.alertController.dismiss();
              let Autologin = KIOSK_AUTO_LOGIN
              this.store.hardlogout()
              location.href = Autologin + '/' + this.terminalid;
            }
          }
        }
      ]
    });
    const paymnet_failed_timeout = setTimeout(() => {
      this.alertController.dismiss();
      this.Page_timer()
      this.counter++;
      this.abort_order()
      this.make_payment();
      if (this.counter == 3) {
        this.abort_order()
        this.store.setUserCart([]);
        this.store.remove("formFields");
        this.store.remove("cart");
        this.store.remove('orderId')
        this.alertController.dismiss();
        let Autologin = KIOSK_AUTO_LOGIN
        this.store.hardlogout()
        location.href = Autologin + '/' + this.terminalid;
      }
    }, telemetryTimeout);
    await alert.present();
  }


  async technical_error(message?) {

    let telemetry_title = " Error ";
    let errorMessage = "<h1>Technical Error</h1>";
    if (message) {
      errorMessage = message;
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      backdropDismiss: false,
      header: telemetry_title,
      message: errorMessage,
      buttons: [
        {
          text: 'Done',
          handler: () => {
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            this.store.remove('orderId')
            let Autologin = KIOSK_AUTO_LOGIN

            this.store.hardlogout()
            location.href = Autologin + '/' + this.terminalid;
          }
        },
        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            this.store.remove('orderId')
            let Autologin = KIOSK_AUTO_LOGIN

            this.store.hardlogout()
            location.href = Autologin + '/' + this.terminalid;
          }
        }
      ]
    });
    await alert.present();
  }

  async error() {
    let terminalid = this.store.get('terminalid')
    this.abort_order()
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      backdropDismiss: false,
      header: 'Oops!',//'Please Tap Again',
      message: '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>',
      buttons: [
        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.store.remove('orderId')
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            this.alertController.dismiss();
            let Autologin = KIOSK_AUTO_LOGIN
            terminalid = this.store.get('terminalid')
            this.store.hardlogout()
            location.href = Autologin + '/' + this.terminalid;
          }
        }
      ]
    });
    setTimeout(() => {
      this.store.remove('orderId')
      this.store.setUserCart([]);
      this.store.remove("formFields");
      this.store.remove("cart");
      this.alertController.dismiss();
      let Autologin = KIOSK_AUTO_LOGIN
      terminalid = this.store.get('terminalid')
      this.store.hardlogout()
      location.href = Autologin + '/' + this.terminalid;
    }, 5000);
    (await alert).present();
  }


  async processing() {

    this.loading = await this.loadingCtrl.create({
      message: '<div  class="conatiner"><ion-img class="ella-logo" src="../../assets/image/Ella_loading.gif" alt="loading..."></ion-img><p class ="text">Processing Your Payment</p><ion-img class="ella-loader" src="../../assets/image/loading.gif" alt="_loading..."></ion-img></div>',
      cssClass: 'loader-class',
      spinner: null
    });
    await this.loading.present();


  }

  async payment_response_modal(message) {
    console.log("modal========================>>>>>", message)
    clearInterval(this.time_left_redirection_counter);
    this.message = message;
    console.log("paymnet failed")
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class payment_failed',
      mode: 'md',
      backdropDismiss: false,
      header: 'Transaction Refused',
      message: '<h1>' + this.message + '</h1> ',
      buttons: [
        {
          text: 'ok',
          handler: () => {
            console.log("paymnet failed ok button.....auto logout")
            clearTimeout(payment_failed_timeout)
            this.abort_order()
            this.abort_order()
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            this.alertController.dismiss();
            let Autologin = KIOSK_AUTO_LOGIN
            this.store.hardlogout()
            location.href = Autologin + '/' + this.terminalid;
          }
        },

      ]
    });
    const payment_failed_timeout = setTimeout(() => {
      this.alertController.dismiss();
      this.Page_timer()
      this.counter++;
      this.abort_order()
      // this.make_payment();
      this.abort_order()
      this.store.setUserCart([]);
      this.store.remove("formFields");
      this.store.remove("cart");
      this.alertController.dismiss();
      let Autologin = KIOSK_AUTO_LOGIN
      this.store.hardlogout()
      console.log("paymnet failed dismiss .....auto logout")
      location.href = Autologin + '/' + this.terminalid;
    }, telemetryTimeout);
    await alert.present();
  }


}

