export const API_URL = 'https://order.ellaapp.com/admin/web/v1/';
export const API_URL2 = 'https://order.ellaapp.com/admin/web/';
export const LOCAL_PAYMENT_API_URL = "http://localhost";
export const LOCAL_PAYMENT_PORT = "5000";
export const SUICA_SERVER = "7700";
export const PRINT_TIMEOUT = 20000;
export const telemetryTimeout = 5000;
export const setIntervalTimeOut =1000;
export const QRcodeTimeout = 10000;
export const cart_time = 30;
export const payment_timer = 30;
export const modalTimeout = 6000;
export const PRINT_API_URL = "http://localhost:3333/?purl=";
export const KIOSK_AUTO_LOGIN ="https://pskiosk.ellaapp.com/kioskautologin/be308506b130e8e09dead705d7afb038/49f292a7f767b8ce10f7f287642d7b03";
export const page_redirection_time = 30;
export const apitimeout = 29000;
export const enable_test_buttons = false;
export const payment_failed_modal = 30;
export const settlement_timer = 20;
export const cancel_timeout = 30000;
export const terminalCheck = 10000;
export const terminal_check_enable = true;
