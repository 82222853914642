import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilityService } from '../services/utility.service';
import { ApiService } from '../services/api.service';
import { KIOSK_AUTO_LOGIN, page_redirection_time, setIntervalTimeOut, terminalCheck } from '../common/api';
import { KioskapiService } from '../services/kioskapi.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.page.html',
  styleUrls: ['./product-detail.page.scss'],
})
export class ProductDetailPage implements OnInit {
  params : any;
  master_menu_type:any;
  addOnPrice: any = {};
  addonsList = [];
  item: any = [];
  radioAddons = {}; selected_addons = [];
  formFields: any ={};
  addons: Array<string> = [];
  quantity = 1;
  total: number;
  itemprice: number = 0;
  itembaseprice: number = 0;
  total_base_price: number;
  totalAddonPriceArray: any=[];
  totalQuantity: any=[];
  currency = "¥";
  showDecimal= true;
  bookmark_customization: any = {};
  requestFromCombo: boolean = false;
  totalvalue:number;
  nutrients:any =[];
 nutrientsValue: any=[];
 terminalId;
 obj_productDetails;
 page_redirection_time_left = page_redirection_time;
 addon_name;
 time_left_redirection_counter : any;
 telemetry_poller;
 terminal_modal;



  constructor(
    private router: Router,
    private kioskauthservice:KioskauthService,
    private route: ActivatedRoute,
    private store:LocalStoreService,
    public alertController: AlertController,
    private utilityService: UtilityService,
    private api: ApiService,
    private kiosk_apiService : KioskapiService
  ) { 
    this.showDecimal= true;
    if(this.store.getCountry()) {
        let countryData = this.store.getCountry();
        this.currency   = countryData['master_country_currency_code'];
        if(countryData['master_country_currency_decimal_places'] == 0){
            this.showDecimal= false;
        }
    }
  }

  ngOnInit() {  
  }

  ionViewWillEnter() {
    this.terminal_modal = this.store.get('terminal_modal')
    setInterval( () =>{
      this.terminal_modal = this.store.get('terminal_modal')
    },500)
    this.kiosk_apiService.telemetry();
    this.telemetry_poller = setInterval(
      ()=>{
       this.kiosk_apiService.telemetry()
      }
    , terminalCheck);
    this.terminalId = this.store.get('terminalid')
    this.reset_time();
    this.kiosk_apiService.audit_log("PRODUCT_DETAIL");
    this.kiosk_apiService.logEvents("PRODUCT_DETAIL")
    this.radioAddons = [];
    this.store.remove('comboAddonPrice');
    this.store.set('formFieldsCombo',[]);
    this.store.set('formFields', []);
    this.kioskauthservice.isRestaurantOperational();
    if(this.route.snapshot.queryParamMap.get('ci')) {
      let items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
      let cartIndex = this.route.snapshot.queryParamMap.get('ci');
      this.quantity = items[cartIndex].length;
      this.requestFromCombo = true;
    }
    this.store.remove('formFields')
    this.getProductDetail();
  }
 
  groupSectiontypeIsMultiple(inputType) {
    if (inputType == 'MULTIPLE') {
      return true;
    } else {
      return false;
    }
  }

  showAmountSection(amount) {
    if (amount == 0 || amount == 0.00) {
      return false;
    } else {
      return true;
    }
  }

  async getProductDetail(){
    let item_uuid = this.route.snapshot.queryParamMap.get('id');
    let data = {
      "ref":"kiosk",
      master_menu_item_uuid: item_uuid,
      user_information_uuid: "",
      restaurant_uuid: this.store.getRestaurantuuid('master_restaurant_uuid'),
    };
    this.obj_productDetails = this.kiosk_apiService.Item_detail(data)
      .subscribe(res => {
        if(res.aaData.is_food_category_time_available == false){
          this.kioskauthservice.itemNotAvailableAlert();
          return false;
        } 
        this.itemprice = 0;
        this.item = res.aaData;
        this.master_menu_type = res.aaData.master_menu_type;
        this.total = res.aaData.master_menu_item_price;  
        this.total_base_price = Number(res.aaData.master_menu_item_price);
        this.itembaseprice = this.total;
        this.calculateTotal();
        this.get_prefilled_values(0);
      },
      err =>{
        console.log(err);
        this.technical_error();
      }
      )
    
  } 

  addQuantity() {
    this.quantity = this.quantity + 1;
    this.calculateTotal();
    this.get_prefilled_values(this.quantity);
  }

  removeQuantity() {
    if (this.quantity - 1 < 1) {
      return false;
    }
    this.quantity = this.quantity - 1;
    this.calculateTotal();
    // Unset object
    let counter = this.quantity;
    let existingData = this.store.get('formFields');
    delete existingData[counter];
    this.store.set('formFields', existingData);

    let i = 0;
    let addOnAmount: number = 0;

    Object.entries(this.totalAddonPriceArray).forEach(([k, v]) => {
      if(counter == i){
        addOnAmount = Number(v) + +addOnAmount;
      }
      i++;
    })
    delete this.totalAddonPriceArray[counter];

  }

  calculateTotal() {  
     this.totalQuantity = [];
     for(let i=1;i<=this.quantity;i++){
        this.totalQuantity.push(i);
     } 
     this.total =  (+(this.quantity * this.itembaseprice)) + +this.itemprice;  
  }

  changeAddonTotal(event,counter,addon) {
    const price = Number(document.getElementById(event.target.value)?.innerText?.slice(1,));
    if (event.target.checked) {
      
      this.radioAddons[event.target.name] = {
        name: event.target.value,
        price,
      };
      
      //this.addons.push(event.target.value);
      //this.store.set('formFields', this.addons);
      this.itemprice = (+this.itemprice) + (+price);
      
      this.calculateTotal();

    } else if (event.target.tagName === 'ION-RADIO-GROUP') {
      if (this.radioAddons[event.target.name]) {
        if (this.radioAddons[event.target.name].price != undefined) {
          this.itemprice = (+this.itemprice) - (+this.radioAddons[event.target.name].price);
        }
      }
      this.radioAddons[event.target.name] = {
        name: event.target.value,
        price,
      };
      this.itemprice = (+this.itemprice) + (+price);
      delete this.totalAddonPriceArray[counter+"_"+addon];
      this.totalAddonPriceArray[counter+"_"+addon] = this.itemprice;
      this.calculateTotal();
    } else {
      //alert(event.target.value+"===="+event.target.checked)
      delete(this.radioAddons[event.target.name])
      this.addons.splice(this.addons.indexOf(event.target.value), 1);
      this.itemprice = (+this.itemprice) - (+price);
      this.calculateTotal();
    }

  }


  async store_in_local(obj,counter) {
    if(counter > 0){
      counter = counter -1;
    }
    let master_addon_group_uuid = obj['master_addon_group_uuid'];
    if (obj.master_addon_group_selection_type == 'SINGLE') {
      if(this.bookmark_customization[master_addon_group_uuid]) {
        this.bookmark_customization[master_addon_group_uuid][0] = obj.master_addon_internal_name;
      }
    } else {
      let type_of_bookmark = typeof this.bookmark_customization[master_addon_group_uuid];
      if (type_of_bookmark == undefined || type_of_bookmark == 'undefined') {
        this.bookmark_customization[master_addon_group_uuid] = [];
      }
      let len = this.bookmark_customization[master_addon_group_uuid].length;
      if(obj.master_addon_name != null){
        this.bookmark_customization[master_addon_group_uuid][len + 1] = obj.master_addon_internal_name;
      }
    }
    let addonsList = [];
        addonsList[counter] = this.bookmark_customization;

    let existingData = this.store.get('formFields');
    if(existingData != null) {
      let finalObj = Object.assign(existingData, addonsList);
      this.store.set('formFields', finalObj);
    } else {
      this.store.set('formFields', addonsList);
    } 
    this.calculateTotal();
  }

   
  get_prefilled_values(counter) {  
    if(counter > 0){
      counter = counter -1;
    }
    this.bookmark_customization= Object.create( {} );
    for (var k in this.item.add_ons) {
      let rows = this.item.add_ons[k];     
      for (var a in rows) {
        if (rows.hasOwnProperty(a)) {
          let row = rows[a];
          //console.log(row[0].master_addon_internal_name)
          let master_addon_group_uuid = row[0].master_addon_group_uuid;
          if(master_addon_group_uuid == '00cbf17c381ae8870077c50de727629e'){
            this.bookmark_customization[master_addon_group_uuid] = [row[0].master_addon_internal_name]
            this.addon_name = row[0].master_addon_internal_name
          } else if(master_addon_group_uuid == 'e19c4ec81d7161fea86eeb2e4f37cb0d'){
            this.bookmark_customization[master_addon_group_uuid] = [row[0].master_addon_internal_name]
          
          } else {
            this.bookmark_customization[master_addon_group_uuid]=[];
          }
         }
      }      
    }
    let addonsList = [];
        addonsList[counter] = this.bookmark_customization;
    let existingData = this.store.get('formFields');
    if(existingData != null) {
      let finalObj = Object.assign(existingData, addonsList);
      this.store.set('formFields', finalObj);
    } else {
      this.store.set('formFields', addonsList);
    }
  }

  addToCart(item){
    let data1 = this.store.get('formFields');
    let existingData1 = this.store.get('selectedItemsInCart');
    if (data1) {
      const items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
      if(this.route.snapshot.queryParamMap.get('ci')) {
        let cartIndex = this.route.snapshot.queryParamMap.get('ci');
        items.splice(cartIndex,1);
        this.store.setUserCart(items);
      }
      let data: any;
      let finalArray: any = [];
      for(var i=0; i<=this.quantity;i++) {
        this.addons = [];
            if(data1[i] != null){
              if(item.master_menu_item_is_combo == 'NO') {
                Object.entries(data1[i]).forEach(([k, v]) => {
                  Object.entries(v).forEach(([k1, v1]) => {
                    let addOnName: any= "";
                        addOnName = v1;
                        if(addOnName != null) {
                          this.addons.push(addOnName);
                        }
                  })
                })
              }

            if(item.master_menu_item_is_combo == 'YES') {
              Object.entries(this.radioAddons).forEach(([k, v]) => {
                if(v['name'] && v['name'] != null) {
                  this.addons.push(v['name']);
                }
              });
            }
            let menu_item_total_price = item.master_menu_item_price;
            let addOnAmount: number = 0;
            Object.entries(this.totalAddonPriceArray).forEach(([kk, vv]) => {
                let arrayCounter: any = 0;
                let addOnPriceKey = kk.split("_");
                    arrayCounter = addOnPriceKey[0];
                if(arrayCounter == i) {
                  addOnAmount = +vv;
                  menu_item_total_price = +vv + +menu_item_total_price;
                }
            })
            data = {
              id: this.route.snapshot.queryParamMap.get('id'),
              name: item.master_menu_item_name,
              thumb: item.master_menu_item_thumbnail ? item.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg',
              type: item.master_menu_type,
              resturant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
              menu_item_total_price: menu_item_total_price,
              item_addon_price: addOnAmount,
              menu_item_price: item.master_menu_item_price,
              total: this.total,
              total_base_price: this.total_base_price,
              quantity: 1, //this.quantity,
              addons: this.addons,
              note: this.store.note ? this.store.note : null,
              food_type: item.master_menu_type,
              total_quantity: this.quantity,
            };
            if (items) {
              finalArray.push(data);
            } else {
              finalArray.push(data);
             
            }
        }
      }
      if (items) {
        items.push(finalArray);
        if (this.store.isLoggedIn()) {
          this.store.setUserCart(items);
        } else { 
          this.store.setCart(items); 
        }
      } else {
        if (this.store.isLoggedIn()) {
          this.store.setUserCart(finalArray);
        } else { 
          this.store.setCart([data]); 
        }
      } 
    }
    setTimeout(() => {
      this.kiosk_apiService.logEvents("ADD_TO_CART");
      this.kiosk_apiService.audit_log("ADD_TO_CART")
    }, 200);

    this.router.navigate([`home`]);
    return false;
    
  }
  
  redirectOnComboItemDetailPage(){
    this.store.set('formFieldsCombo',[]);
    this.store.set('comboAddonPrice',0);
    let addonArray = [];
    let comboAddonPrice = 0;
    Object.entries(this.radioAddons).forEach(([k, v]) => {
      if(v['name'] && v['name'] != null) {
        addonArray.push(v['name']);
        comboAddonPrice += v['price']
      }
    });
    this.store.set('formFieldsCombo',addonArray);
    this.store.set('comboAddonPrice',comboAddonPrice);
    this.router.navigateByUrl(`product-combo-detail?id=` + this.item.master_menu_item_uuid+`&from=set`);
  }
  
  ionViewDidLeave(){
    clearInterval( this.time_left_redirection_counter);
    clearInterval( this.telemetry_poller);
    clearInterval( this.terminal_modal);
  }


  reset_time(){
    clearInterval( this.time_left_redirection_counter);
    this.page_redirection_time_left = page_redirection_time ;
  
    if( this.page_redirection_time_left = page_redirection_time){
    
    this.time_left_redirection_counter = setInterval(()=>{
      this.page_redirection_time_left -= 1;
  
      if (this.page_redirection_time_left == 0) {
        this.kiosk_apiService.terminal_dismiss()
        clearInterval( this.telemetry_poller);
        clearInterval( this.terminal_modal);

        clearInterval( this.time_left_redirection_counter);
        let Autologin = KIOSK_AUTO_LOGIN
        this.store.hardlogout()
        location.href = Autologin + '/' + this.terminalId;

        return false;
      } 
    }, setIntervalTimeOut);
    }
   
    
  }
  
  logScrollStart(event) {
    clearInterval( this.time_left_redirection_counter);
    this.reset_time();
  
  }
  
  logScrolling(event) {
    clearInterval( this.time_left_redirection_counter);
    this.reset_time();
  
  }
  
  logScrollEnd(event) {
    clearInterval( this.time_left_redirection_counter);
    this.reset_time();
  
  }

go_to_maintenance() {
    let terminalid = localStorage.getItem('terminalid')
    terminalid = JSON.parse(terminalid)
    location.href = "http://192.168.10.11:9001/?terminalid="+ terminalid;
  }

  async technical_error(){
    const alert =  this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: 'Oops!',//'Please Tap Again',
      message: '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>',
      buttons: [ 
  
      {
       text: '',
       cssClass: 'cross',
      handler: () => {
       this.alertController.dismiss();
      this.router.navigateByUrl('/Scan');
       }
     }
      ]
      });
       setTimeout(() => { this.router.navigateByUrl('/Scan'),this.alertController.dismiss();},5000);
       (await alert).present();
    }

}
