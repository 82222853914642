import { Component, OnInit } from '@angular/core';
import { KioskauthService } from '../services/kioskauth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStoreService } from '../services/localstore.service';
import { UtilityService } from '../services/utility.service';
import { AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { KioskapiService } from '../services/kioskapi.service';
import { terminalCheck } from '../common/api';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.page.html',
  styleUrls: ['./scan.page.scss'],
})
export class ScanPage implements OnInit {
  terminal_modal;
  is_maintaince_mode_on;
  appSettings: any;
  shop: any;
  type="0"
  settings={};
  payment:any;
  telemetry_poller:any;
  is_restaurant_closed_status;
  app_maintanance_description2;
  app_ella_maintanance_description2;
  app_store_closed_description2;
  obj_appsetting;
  pageImage = "";
  screenMode = "false"
  store_info: any;
  store_close_status;
  obj_restaurantDetails;
  obj_restaurantstatus;
  restaurant_mode;
  screen_image_timer
  store_data;
  is_ella_maintaince_mode_on:boolean

  constructor(private router: Router,
    private store:LocalStoreService,
    private utilityService: UtilityService,
    private kiosk_apiService : KioskapiService, 
    public alertController: AlertController) {
      this.getAppSettings();
      this.is_restaurant_closed();
      this.getRestaurantDetails();
      this.is_maintaince_mode_on = "true";
    this.is_restaurant_closed_status = "NO"
    }

    ionViewWillEnter() {
      this.getAppSettings();
      this.terminal_modal = this.store.get('terminal_modal')
      setInterval( () =>{
        this.terminal_modal = this.store.get('terminal_modal')
      },500)
      this.kiosk_apiService.telemetry();
      this.telemetry_poller = setInterval(
        ()=>{
         this.kiosk_apiService.telemetry()
        }
      , terminalCheck);
      this.is_restaurant_closed();
      this.getRestaurantDetails();

      this.store_close_status = setInterval(
        () => {
          this.is_restaurant_closed();
        }
        , 10000);
      this.restaurant_mode = setInterval(
          () => {
            
            this.getRestaurantDetails();
          }
          , 12000);
          this.kiosk_apiService.audit_log("SCAN");
          this.kiosk_apiService.logEvents("SCAN")
    }
    
  ngOnInit() {
    this.is_restaurant_closed();
    this.getRestaurantDetails();
    this.is_maintaince_mode_on = "true";
    this.is_restaurant_closed_status = "NO"
  }


  
  getRestaurantDetails(){
    let param = {
      "ref":"kiosk",
        master_restaurant_uuid: localStorage.getItem("master_restaurant_uuid")
    }
   this.obj_restaurantDetails = this.kiosk_apiService.restaurant_Details()
        .subscribe((res) => {
        if (res.statusCode == 200) {    
          localStorage.removeItem('restaurant');
          this.store.set('restaurant',res.aaData);
          this.store_info = res.aaData
          if(this.store_info.master_restaurant_status == "ELLA_M_ON"){
            this.is_ella_maintaince_mode_on = true;
          }
          else{
            this.is_ella_maintaince_mode_on = false;
          }
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          if(this.shop.master_restaurant_status == 'M_ON'){
            this.is_maintaince_mode_on ='true';
          }
          else{
            this.is_maintaince_mode_on = 'false';
          }
          // this.Scan_image()
        } 
    }, 
      err =>
          {
            this.technical_error();
          
    });
  }

  getAppSettings() {

    this.obj_appsetting = this.kiosk_apiService.appsettings().subscribe(response => {
       if (response.status) {
         this.appSettings = response.aaData;
        //  console.log("setting", this.appSettings)
         this.settings = this.appSettings;
        this.app_maintanance_description2 = response.aaData.app_settings.app_maintanance_description ;
        this.app_ella_maintanance_description2 = response.aaData.app_settings.app_ella_maintanance_description;
        this.app_store_closed_description2 = response.aaData.app_settings.app_store_closed_description ;        
       }
     }, err => {
       this.technical_error();
     });
   }

go_to_maintenance() {
    let terminalid = localStorage.getItem('terminalid')
    terminalid = JSON.parse(terminalid)
    location.href = "http://192.168.10.11:9001/?terminalid="+ terminalid;
  }

  is_restaurant_closed(){
    let restaurantDetails = this.store.getRestaurant();  
    let param = {
          "master_restaurant_uuid": restaurantDetails.master_restaurant_uuid
      }
   this.obj_restaurantstatus = this.utilityService.apiReq('post', 'masterrestaurant/isrestaurantoperational', param)
      .subscribe(
          (res) => {
            // console.log("ature", res)
              if (res.statusCode == 200) {
                if(res.aaData == true) {
                  this.is_restaurant_closed_status= "NO";
                } else {                  
                  this.is_restaurant_closed_status= "YES";
                }
                // this.Scan_image()
              } 
          },
          err =>
          {
            // console.log(err);
            this.technical_error();
          }
      );
 }

 ionViewDidLeave() {
  clearInterval(this.restaurant_mode);
  clearInterval(this.telemetry_poller);
  clearInterval(this.terminal_modal)
  clearInterval(this.store_close_status)
  clearInterval(this.screen_image_timer)
  this.obj_restaurantstatus.unsubscribe();
  this.obj_restaurantDetails.unsubscribe();
  
}

async technical_error(){
  const alert =  this.alertController.create({
    cssClass: 'my-custom-class',
    mode: 'md',
    header: 'Oops!',//'Please Tap Again',
    message: '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>',
    buttons: [ 

    {
     text: '',
     cssClass: 'cross',
    handler: () => {
     this.alertController.dismiss();
    this.router.navigateByUrl('/Scan');
     }
   }
    ]
    });
     setTimeout(() => { this.router.navigateByUrl('/Scan'),this.alertController.dismiss();},5000);
     (await alert).present();
  }
  
}
