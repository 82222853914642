import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, ActivationStart, Router, RouterEvent, NavigationEnd } from "@angular/router";
import { AlertController, ModalController } from '@ionic/angular';
import { LocalStoreService } from '../services/localstore.service';
import { KioskauthService } from '../services/kioskauth.service';
import { TranslateConfigService } from '../services/translate-config.service';

@Component({
  selector: 'app-product-combo-detail',
  templateUrl: './product-combo-detail.page.html',
  styleUrls: ['./product-combo-detail.page.scss'],
})
export class ProductComboDetailPage implements OnInit {
  params : any;
  selectedItems: any = [];
  comboItem: any =[]
  addOnPrice: any = {};
  addonsList = [];
  item: any = [];
  radioAddons = {}; selected_addons = [];
  formFields: any ={};
  addons: Array<string> = [];
  quantity = 1;
  total: number;
  itemprice: number = 0;
  itembaseprice: number = 0;
  comboPrice: number = 0;
  total_base_price: number;
  comboPriceArray: any = [];
  totalAddonPriceArray: any=[];
  totalQuantity: any=[];
  currency = "¥";
  showDecimal= true;
  bookmark_customization: any = {};
  itemList: any = [];
  selected_item = "";
  selectedvalue:  any = [];
  nutrients:any =[];
  nutrientsValue: any=[];
   
  constructor(
    public modalCtrl: ModalController,
    private router: Router,
    private store:LocalStoreService,
    private kioskauthservice:KioskauthService,
    private route: ActivatedRoute,
    private translateConfigService: TranslateConfigService,
    public alertController: AlertController
  ) { 
    this.translateConfigService.getDefaultLanguage();
    this.showDecimal= true;
    if(this.store.getCountry()) {
        let countryData = this.store.getCountry();
        this.currency   = countryData['master_country_currency_code'];
        if(countryData['master_country_currency_decimal_places'] == 0){
            this.showDecimal= false;
        }
    }
  }

  ngOnInit() {
    this.getComboItems();
  }

  ionViewWillEnter(){
  }
  
  getComboItems(){
    let discountFlag = "";
    if(this.route.snapshot.queryParamMap.get('from')) {
      discountFlag = "yes";
    }
    let master_restaurant_uuid  = localStorage.getItem("master_restaurant_uuid");
    let payload = {
      "ref":"kiosk",
      "restaurant_uuid":master_restaurant_uuid,
      "combo_menu_uuid":this.route.snapshot.queryParamMap.get('id'),
      "discount_flag": discountFlag
      };
    this.kioskauthservice
      .apiReq('post', 'mastermenuitem/getcomboitemlistbyrestaurantuuid', payload)
      .subscribe((response) => {
          let data        = response.aaData;
          this.itemList   = data.items;
          this.comboItem  = data.combo;
          this.comboPrice = this.comboItem.master_menu_item_price;
          if(this.store.get('comboAddonPrice')) {
            this.comboPrice = +this.comboPrice + +this.store.get('comboAddonPrice');
          }
          this.selected_item = this.itemList[0].master_menu_item_uuid;
          this.getProductDetail(this.itemList[0].master_menu_item_uuid,1,this.comboItem.master_food_category_uuid);
      });
  }

  getProductDetail(item_uuid,counter,master_food_category_uuid){
    let discountFlag = "";
    if(this.route.snapshot.queryParamMap.get('from')) {
      discountFlag = "yes";
    }
    let data = {
      "ref":"kiosk",
      master_menu_item_uuid: item_uuid,
      combo_food_category_uuid: master_food_category_uuid,
      user_information_uuid: "",
      restaurant_uuid: this.store.getRestaurantuuid('master_restaurant_uuid'),
      discount_flag: discountFlag
    };
    this.kioskauthservice.apiReq('post', 'mastermenuitem/getitemdetailbyitemuuid', data)
      .subscribe(res => {
        this.item = res.aaData;
              counter = counter - 1;
              this.selectedItems[counter] = item_uuid;
              this.comboPriceArray[counter] = res.aaData.master_menu_item_price;
              this.total = this.comboPriceArray.reduce(function (a, b) {return +a + +b;}, 0);
              this.nutrients = this.item.master_menu_item_nutritional_info;
              this.nutrients = JSON.parse(this.nutrients);
              this.nutrients = JSON.parse(this.nutrients);
              //this.total = res.aaData.master_menu_item_price;  
              this.total_base_price = Number(res.aaData.master_menu_item_price);
              this.itembaseprice = this.total;
              this.calculateTotal();
              this.get_prefilled_values(0);
      })
  }

  addQuantity() {
    this.quantity = this.quantity + 1;
    let counter   = this.quantity - 1
    this.comboPriceArray[counter] = this.itemList[0]?.master_menu_item_price;
    this.total = this.comboPriceArray.reduce(function (a, b) {return +a + +b;}, 0);
    this.calculateTotal();
    this.get_prefilled_values(this.quantity);
     // ------nutrition value increase on click-------------------------
    this.nutrients[0].value =  this.nutrients[0].value * this.quantity;
    this.nutrients[1].value =  this.nutrients[1].value * this.quantity;
    this.nutrients[2].value =  this.nutrients[2].value * this.quantity;
    this.nutrients[3].value =  this.nutrients[3].value * this.quantity; 
  //  ---------------
  }

  removeQuantity() {
    if (this.quantity - 1 < 1) {
      return false;
    }
    this.quantity = this.quantity - 1;
    this.calculateTotal();
    // Unset object
    let counter = this.quantity;
    let existingData = this.store.get('formFields');
    delete existingData[counter];
    delete this.comboPriceArray[counter]
    this.store.set('formFields', existingData);
    let i = 0;
    let addOnAmount: number = 0;
    Object.entries(this.totalAddonPriceArray).forEach(([k, v]) => {
      if(counter == i){
        addOnAmount = Number(v) + +addOnAmount;
      }
      i++;
    })
    delete this.totalAddonPriceArray[counter];
    let sum = this.comboPriceArray.reduce(function (a, b) {return +a + +b;}, 0);
    this.total = sum + +this.comboPrice;
    // ------nutrition value deccrease on click-------------------------
  }

  calculateTotal() {  
     this.totalQuantity = [];
     for(let i=1;i<=this.quantity;i++){
        this.totalQuantity.push(i);
     } 
     let sum = this.comboPriceArray.reduce(function (a, b) {return +a + +b;}, 0);
     this.total = sum + +this.comboPrice + +this.itemprice;
  }

  changeAddonTotal(event,counter,addon) {
    if(counter > 0 ){
      counter = counter - 1;
    }
    const price = Number(document.getElementById(event.target.value)?.innerText?.slice(1,));
    if (event.target.checked) {
      this.radioAddons[event.target.name] = {
        name: event.target.value,
        price,
      };
      this.addons.push(event.target.value);
      this.itemprice = (+this.itemprice) + (+price);
      this.calculateTotal();
      if (event.target.name == '0bee7326f75d02db77fd544767185c53') {
        this.addons.push(event.target.value);
        this.itemprice = (+this.itemprice) + (+price);
        this.calculateTotal();
      }

    } else if (event.target.tagName === 'ION-RADIO-GROUP') {
      if (this.radioAddons[event.target.name]) {
        if (this.radioAddons[event.target.name].price != undefined) {
          this.itemprice = (+this.itemprice) - (+this.radioAddons[event.target.name].price);
        }
      }
      this.radioAddons[event.target.name] = {
        name: event.target.value,
        price,
      };
      this.itemprice = (+this.itemprice) + (+price);
      delete this.totalAddonPriceArray[counter+"_"+addon];
      this.totalAddonPriceArray[counter+"_"+addon] = this.itemprice;
      
      this.calculateTotal();
    } else {
      delete(this.radioAddons[event.target.name])
      this.addons.splice(this.addons.indexOf(event.target.value), 1);
      this.itemprice = (+this.itemprice) - (+price);
      this.calculateTotal();
    }
  }

  async store_in_local(event,obj,counter) {
      if(counter > 0){
        counter = counter -1;
      }
      let master_addon_group_uuid = obj['master_addon_group_uuid'];
      if (obj.master_addon_group_selection_type == 'SINGLE') {
        if(this.bookmark_customization[master_addon_group_uuid]) {
          this.bookmark_customization[master_addon_group_uuid][0] = obj.master_addon_internal_name;
          this.selectedvalue=  this.bookmark_customization[master_addon_group_uuid][0];
        }    
      } else {
        console.log(obj.master_addon_group_selection_type,"hhhhhhhhhhh");
        let type_of_bookmark = typeof this.bookmark_customization[master_addon_group_uuid];
        if (type_of_bookmark == undefined || type_of_bookmark == 'undefined') { 
          this.bookmark_customization[master_addon_group_uuid] = [];
        }
        let len = this.bookmark_customization[master_addon_group_uuid].length;
        if(obj.master_addon_name != null){
          this.bookmark_customization[master_addon_group_uuid][len + 1] = obj.master_addon_internal_name;
        }
      }
      let addonsList = [];
      if(obj.master_addon_group_selection_type == "MULTIPLE"){
        if (event.target.checked) {
          // delete [master_addon_group_uuid];
        } else {
          addonsList[counter] = this.bookmark_customization;
        }
      } else {
        addonsList[counter] = this.bookmark_customization;
      }
    
      let existingData = this.store.get('formFields');
      if(existingData != null) {
        let finalObj = Object.assign(existingData, addonsList);
        this.store.set('formFields', finalObj);
      } else {
        this.store.set('formFields', addonsList);
      }
      this.calculateTotal();
    }

  get_prefilled_values(counter) {  
    if(counter > 0){
      counter = counter -1;
    }
    this.bookmark_customization= Object.create( {} );
    for (var k in this.item.add_ons) {
      let rows = this.item.add_ons[k];     
      for (var a in rows) {
        if (rows.hasOwnProperty(a)) {
          let row = rows[a];
          let master_addon_group_uuid = row[0].master_addon_group_uuid;
          if(master_addon_group_uuid == '487628b5db31d60e3dec784dcd35b854'){
            this.bookmark_customization[master_addon_group_uuid] = ["Fresh Milk"]
          } else if(master_addon_group_uuid == '6fde901f2a2b141657b9009c892febca'){
            this.bookmark_customization[master_addon_group_uuid] = ["Hot"]
          } else if(master_addon_group_uuid == 'eb3debd88e550c5557942dbc356c804d'){
            this.bookmark_customization[master_addon_group_uuid] = ["Less Sugar"]
          } else {
            this.bookmark_customization[master_addon_group_uuid]=[];
          }
         }
      }      
    }
    let addonsList = [];
        addonsList[counter] = this.bookmark_customization;
    let existingData = this.store.get('formFields');
    if(existingData != null) {
      let finalObj = Object.assign(existingData, addonsList);
      this.store.set('formFields', finalObj);
    } else {
      this.store.set('formFields', addonsList);
    }
  }

  addToCart(item){
    let data1 = this.store.get('formFields');
    let comboItems = "";
    if (data1) {
      const items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
      if(this.route.snapshot.queryParamMap.get('ci')) {
        let cartIndex = this.route.snapshot.queryParamMap.get('ci');
        items.splice(cartIndex,1);
        this.store.setUserCart(items);
      }
      
      let comboAddon = [];
      if(this.store.get('formFieldsCombo')) {
          comboAddon = this.store.get('formFieldsCombo');
        }
      let data: any;
      let finalArray: any = [];
      let cData = { 
        id: this.comboItem.master_menu_item_uuid,
        combo_uuid: this.comboItem.master_menu_item_uuid,
        name: this.comboItem.master_menu_item_name,
        thumb: this.comboItem.master_menu_item_thumbnail ? this.comboItem.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg',
        type: this.comboItem.master_menu_type,
        resturant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
        menu_item_total_price: this.comboItem.master_menu_item_price,
        item_addon_price: 0,
        menu_item_price: this.comboItem.master_menu_item_price,
        total: this.total,
        total_base_price: this.comboItem.master_menu_item_price,
        quantity: 1,
        addons: comboAddon,
        note: this.store.note ? this.store.note : null,
        is_combo: "YES",
        is_main_combo_item: "YES",
        food_type: this.comboItem.master_menu_type,
        total_quantity: this.totalQuantity.length
      }
      finalArray.push(cData);
      comboItems += this.comboItem.master_menu_item_name;
      for(var i=0; i<this.quantity;i++) {
        this.addons = [];
            if(data1[i] != null){
              Object.entries(data1[i]).forEach(([k, v]) => {
                Object.entries(v).forEach(([k1, v1]) => {
                  let addOnName: any= "";
                      addOnName = v1;
                      if(addOnName != null) {
                        this.addons.push(addOnName);
                      }
                })
              })
            let menu_item_total_price = item.master_menu_item_price;
            let addOnAmount: number = 0;
            Object.entries(this.totalAddonPriceArray).forEach(([kk, vv]) => {
                let arrayCounter: any = 0;
                let addOnPriceKey = kk.split("_");
                    arrayCounter = addOnPriceKey[0];
                if(arrayCounter == i) {
                  addOnAmount = +vv;
                  menu_item_total_price = +vv + +menu_item_total_price;
                }
            })
           
            data = {
                id: this.selectedItems[i],
                combo_uuid: this.comboItem.master_menu_item_uuid,
                name: item.master_menu_item_name,
                thumb: item.master_menu_item_thumbnail ? item.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg',
                type: item.master_menu_type,
                resturant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
                menu_item_total_price: menu_item_total_price,
                item_addon_price: addOnAmount,
                menu_item_price: item.master_menu_item_price,
                total: this.total,
                total_base_price: this.total_base_price,
                quantity: this.quantity,
                addons: this.addons,
                note: this.store.note ? this.store.note : null,
                is_combo: "YES",
                is_main_combo_item: "NO",
                food_type: item.master_menu_type,
                total_quantity: this.totalQuantity.length
            };
            if (items) {
              finalArray.push(data);
            } else {
              finalArray.push(data);
            }
        }
        comboItems += ","+item.master_menu_item_name;
      }

      
      if (items) {
        items.push(finalArray);
        if (this.store.isLoggedIn()) {
          this.store.setUserCart(items);
        } else { 
          this.store.setCart(items); 
        }
      } else {
        if (this.store.isLoggedIn()) {
          this.store.setUserCart(finalArray);
        } else { 
          this.store.setCart([data]); 
        }
      }
    }    
    this.router.navigate([`home`]);
    return false;
  }

  ionViewDidLeave(){ 
  }

  go_to_maintenance() {
    let terminalid = localStorage.getItem('terminalid')
    terminalid = JSON.parse(terminalid)
    location.href = "http://192.168.10.11:9001/?terminalid="+ terminalid;
  }
}
